import { Options, Vue } from 'vue-class-component';
import { getFirestore } from 'firebase/firestore';
import { collection, getDocs } from 'firebase/firestore';
import { Gig } from '@/interfaces/gig';
import { useStore } from 'vuex';
import ShowButton from '@/components/ShowButton/ShowButton.vue';

@Options({
    components: {
        ShowButton,
    },
})
export default class Shows extends Vue {
    private store = useStore();
    private allGigs: Array<Gig> = new Array<Gig>();
    private upcomingGigs: Array<Gig> = new Array<Gig>();
    private pastGigs: Array<Gig> = new Array<Gig>();
    private pastGigsSorted: Array<any> = new Array<any>();
    private showShows = false;

    public async mounted(): Promise<void> {
        await this.getShows();
        this.showShows = true;
    }

    private async getShows(): Promise<void> {
        const urlOne = 'https://lucatescari.github.io/rtgigs/allgigs.json';

        try {
            const response = await fetch(urlOne);
            const test = await response.json();
            this.allGigs = test;
        } catch (e) {
            console.error('shits fucked');
            console.log(e);
        }

        this.allGigs = this.allGigs.filter((gig: Gig) => gig.public);

        this.allGigs = this.allGigs.sort((gigA: Gig, gigB: Gig) => {
            if (!gigA.actualDate || !gigB.actualDate) return 0;

            return new Date(gigB.actualDate.seconds * 1000).valueOf() - new Date(gigA.actualDate.seconds * 1000).valueOf();
        });

        this.allGigs.forEach((gig: Gig) => {
            if (gig.actualDate) {
                if (new Date(gig.actualDate.seconds * 1000) >= new Date(Date.now())) {
                    this.upcomingGigs.push(gig);
                }

                if (new Date(gig.actualDate.seconds * 1000) < new Date(Date.now())) {
                    this.pastGigs.push(gig);
                }
            }
        });

        this.pastGigsSorted = this.pastGigs.reduce(function (r, a) {
            if (a.actualDate) {
                r[new Date(a.actualDate.seconds * 1000).getFullYear()] = r[new Date(a.actualDate.seconds * 1000).getFullYear()] || [];
                r[new Date(a.actualDate.seconds * 1000).getFullYear()].push(a);
                return r;
            }
        }, Object.create(null));
    }

    private toLocalDate(date: Date): string {
        const lang = this.$i18n.locale;

        if (lang == 'de') {
            return date.toLocaleString('de-CH', { year: 'numeric', month: 'long', day: 'numeric' });
        } else {
            return date.toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
        }
    }
}
