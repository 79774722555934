<template>
    <div class="shows content-wide">
        <div class="content-img-container">
            <!-- <img src="@/assets/img/3.jpeg" :alt="$t('shows.title')" /> -->
        </div>

        <h1 class="big-title">{{ $t('shows.title') }}</h1>

        <Transition name="fade">
            <div v-show="showShows" class="shows-container">
                <div class="upcoming">
                    <h2>{{ $t('shows.upcomingShows') }}</h2>
                    <ShowButton
                        v-for="gig in upcomingGigs"
                        :key="gig.name + Math.random()"
                        :date="new Date(gig.actualDate.seconds * 1000)"
                        :title="gig.name ? gig.name : gig.location"
                        :link="gig.link"
                        :darkMode="true" />
                </div>
                <div class="archive">
                    <h2>{{ $t('shows.archive') }}</h2>
                    <div v-if="pastGigsSorted[2022]">
                        <h3>{{ new Date(pastGigsSorted[2022][0].actualDate.seconds * 1000).getFullYear()  }}</h3>
                        <div v-for="gig in pastGigsSorted[2022]" :key="gig.name + Math.random()" class="archive-gig">
                            <div>{{ toLocalDate(new Date(gig.actualDate.seconds * 1000)) }}</div>
                            <div>{{ gig.name ? gig.name : gig.location }}</div>
                        </div>
                    </div>
                    <div v-if="pastGigsSorted[2021]">
                        <h3>{{ new Date(pastGigsSorted[2021][0].actualDate.seconds * 1000).getFullYear()  }}</h3>
                        <div v-for="gig in pastGigsSorted[2021]" :key="gig.name + Math.random()" class="archive-gig">
                            <div>{{ toLocalDate(new Date(gig.actualDate.seconds * 1000)) }}</div>
                            <div>{{ gig.name ? gig.name : gig.location }}</div>
                        </div>
                    </div>
                    <div v-if="pastGigsSorted[2020]">
                        <h3>{{ new Date(pastGigsSorted[2020][0].actualDate.seconds * 1000).getFullYear()  }}</h3>
                        <div v-for="gig in pastGigsSorted[2020]" :key="gig.name + Math.random()" class="archive-gig">
                            <div>{{ toLocalDate(new Date(gig.actualDate.seconds * 1000)) }}</div>
                            <div>{{ gig.name ? gig.name : gig.location }}</div>
                        </div>
                    </div>
                    <div v-if="pastGigsSorted[2019]">
                        <h3>{{ new Date(pastGigsSorted[2019][0].actualDate.seconds * 1000).getFullYear()  }}</h3>
                        <div v-for="gig in pastGigsSorted[2019]" :key="gig.name + Math.random()" class="archive-gig">
                            <div>{{ toLocalDate(new Date(gig.actualDate.seconds * 1000)) }}</div>
                            <div>{{ gig.name ? gig.name : gig.location }}</div>
                        </div>
                    </div>
                    <div v-if="pastGigsSorted[2018]">
                        <h3>{{ new Date(pastGigsSorted[2018][0].actualDate.seconds * 1000).getFullYear()  }}</h3>
                        <div v-for="gig in pastGigsSorted[2018]" :key="gig.name + Math.random()" class="archive-gig">
                            <div>{{ toLocalDate(new Date(gig.actualDate.seconds * 1000)) }}</div>
                            <div>{{ gig.name ? gig.name : gig.location }}</div>
                        </div>
                    </div>
                    <div v-if="pastGigsSorted[2017]">
                        <h3>{{ new Date(pastGigsSorted[2017][0].actualDate.seconds * 1000).getFullYear()  }}</h3>
                        <div v-for="gig in pastGigsSorted[2017]" :key="gig.name + Math.random()" class="archive-gig">
                            <div>{{ toLocalDate(new Date(gig.actualDate.seconds * 1000)) }}</div>
                            <div>{{ gig.name ? gig.name : gig.location }}</div>
                        </div>
                    </div>
                    <div v-if="pastGigsSorted[2016]">
                        <h3>{{ new Date(pastGigsSorted[2016][0].actualDate.seconds * 1000).getFullYear()  }}</h3>
                        <div v-for="gig in pastGigsSorted[2016]" :key="gig.name + Math.random()" class="archive-gig">
                            <div>{{ toLocalDate(new Date(gig.actualDate.seconds * 1000)) }}</div>
                            <div>{{ gig.name ? gig.name : gig.location }}</div>
                        </div>
                    </div>
                    <div v-if="pastGigsSorted[2015]">
                        <h3>{{ new Date(pastGigsSorted[2015][0].actualDate.seconds * 1000).getFullYear()  }}</h3>
                        <div v-for="gig in pastGigsSorted[2015]" :key="gig.name + Math.random()" class="archive-gig">
                            <div>{{ toLocalDate(new Date(gig.actualDate.seconds * 1000)) }}</div>
                            <div>{{ gig.name ? gig.name : gig.location }}</div>
                        </div>
                    </div>
                    <div v-if="pastGigsSorted[2014]">
                        <h3>{{ new Date(pastGigsSorted[2014][0].actualDate.seconds * 1000).getFullYear()  }}</h3>
                        <div v-for="gig in pastGigsSorted[2014]" :key="gig.name + Math.random()" class="archive-gig">
                            <div>{{ toLocalDate(new Date(gig.actualDate.seconds * 1000)) }}</div>
                            <div>{{ gig.name ? gig.name : gig.location }}</div>
                        </div>
                    </div>
                    <div v-if="pastGigsSorted[2013]">
                        <h3>{{ new Date(pastGigsSorted[2013][0].actualDate.seconds * 1000).getFullYear()  }}</h3>
                        <div v-for="gig in pastGigsSorted[2013]" :key="gig.name + Math.random()" class="archive-gig">
                            <div>{{ toLocalDate(new Date(gig.actualDate.seconds * 1000)) }}</div>
                            <div>{{ gig.name ? gig.name : gig.location }}</div>
                        </div>
                    </div>
                    <div v-if="pastGigsSorted[2012]">
                        <h3>{{ new Date(pastGigsSorted[2012][0].actualDate.seconds * 1000).getFullYear()  }}</h3>
                        <div v-for="gig in pastGigsSorted[2012]" :key="gig.name + Math.random()" class="archive-gig">
                            <div>{{ toLocalDate(new Date(gig.actualDate.seconds * 1000)) }}</div>
                            <div>{{ gig.name ? gig.name : gig.location }}</div>
                        </div>
                    </div>
                    <div v-if="pastGigsSorted[2011]">
                        <h3>{{ new Date(pastGigsSorted[2011][0].actualDate.seconds * 1000).getFullYear()  }}</h3>
                        <div v-for="gig in pastGigsSorted[2011]" :key="gig.name + Math.random()" class="archive-gig">
                            <div>{{ toLocalDate(new Date(gig.actualDate.seconds * 1000)) }}</div>
                            <div>{{ gig.name ? gig.name : gig.location }}</div>
                        </div>
                    </div>
                    <div v-if="pastGigsSorted[2010]">
                        <h3>{{ new Date(pastGigsSorted[2010][0].actualDate.seconds * 1000).getFullYear()  }}</h3>
                        <div v-for="gig in pastGigsSorted[2010]" :key="gig.name + Math.random()" class="archive-gig">
                            <div>{{ toLocalDate(new Date(gig.actualDate.seconds * 1000)) }}</div>
                            <div>{{ gig.name ? gig.name : gig.location }}</div>
                        </div>
                    </div>
                    <div v-if="pastGigsSorted[2009]">
                        <h3>{{ new Date(pastGigsSorted[2009][0].actualDate.seconds * 1000).getFullYear()  }}</h3>
                        <div v-for="gig in pastGigsSorted[2009]" :key="gig.name + Math.random()" class="archive-gig">
                            <div>{{ toLocalDate(new Date(gig.actualDate.seconds * 1000)) }}</div>
                            <div>{{ gig.name ? gig.name : gig.location }}</div>
                        </div>
                    </div>
                    <div v-if="pastGigsSorted[2008]">
                        <h3>{{ new Date(pastGigsSorted[2008][0].actualDate.seconds * 1000).getFullYear()  }}</h3>
                        <div v-for="gig in pastGigsSorted[2008]" :key="gig.name + Math.random()" class="archive-gig">
                            <div>{{ toLocalDate(new Date(gig.actualDate.seconds * 1000)) }}</div>
                            <div>{{ gig.name ? gig.name : gig.location }}</div>
                        </div>
                    </div>
                    <div v-if="pastGigsSorted[2007]">
                        <h3>{{ new Date(pastGigsSorted[2007][0].actualDate.seconds * 1000).getFullYear()  }}</h3>
                        <div v-for="gig in pastGigsSorted[2007]" :key="gig.name + Math.random()" class="archive-gig">
                            <div>{{ toLocalDate(new Date(gig.actualDate.seconds * 1000)) }}</div>
                            <div>{{ gig.name ? gig.name : gig.location }}</div>
                        </div>
                    </div>
                    <div v-if="pastGigsSorted[2006]">
                        <h3>{{ new Date(pastGigsSorted[2006][0].actualDate.seconds * 1000).getFullYear()  }}</h3>
                        <div v-for="gig in pastGigsSorted[2006]" :key="gig.name + Math.random()" class="archive-gig">
                            <div>{{ toLocalDate(new Date(gig.actualDate.seconds * 1000)) }}</div>
                            <div>{{ gig.name ? gig.name : gig.location }}</div>
                        </div>
                    </div>
                    <div v-if="pastGigsSorted[2005]">
                        <h3>{{ new Date(pastGigsSorted[2005][0].actualDate.seconds * 1000).getFullYear()  }}</h3>
                        <div v-for="gig in pastGigsSorted[2005]" :key="gig.name + Math.random()" class="archive-gig">
                            <div>{{ toLocalDate(new Date(gig.actualDate.seconds * 1000)) }}</div>
                            <div>{{ gig.name ? gig.name : gig.location }}</div>
                        </div>
                    </div>
                    <div v-if="pastGigsSorted[2004]">
                        <h3>{{ new Date(pastGigsSorted[2004][0].actualDate.seconds * 1000).getFullYear()  }}</h3>
                        <div v-for="gig in pastGigsSorted[2004]" :key="gig.name + Math.random()" class="archive-gig">
                            <div>{{ toLocalDate(new Date(gig.actualDate.seconds * 1000)) }}</div>
                            <div>{{ gig.name ? gig.name : gig.location }}</div>
                        </div>
                    </div>
                    <div v-if="pastGigsSorted[2003]">
                        <h3>{{ new Date(pastGigsSorted[2003][0].actualDate.seconds * 1000).getFullYear()  }}</h3>
                        <div v-for="gig in pastGigsSorted[2003]" :key="gig.name + Math.random()" class="archive-gig">
                            <div>{{ toLocalDate(new Date(gig.actualDate.seconds * 1000)) }}</div>
                            <div>{{ gig.name ? gig.name : gig.location }}</div>
                        </div>
                    </div>
                    <!--
                    <div v-for="year in pastGigsSorted" :key="year">
                        <h3>{{ new Date(year[0].actualDate.seconds * 1000).getFullYear()  }}</h3>
                        <div v-for="gig in year" :key="gig.name + Math.random()" class="archive-gig">
                            <div>{{ toLocalDate(new Date(gig.actualDate.seconds * 1000)) }}</div>
                            <div>{{ gig.name ? gig.name : gig.location }}</div>
                        </div>
                    </div>
                    -->
                </div>
            </div>
        </Transition>
    </div>
</template>

<script src="./Shows.ts"></script>
<style lang="scss" scoped>
@import './Shows.scss';
</style>
